.app {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(100vh, 100vw);
    height: min(100vh, 100vw);
    margin: 0 auto;

    .small-lines {
        position: absolute;
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        gap: 50px;
        padding: 10px 30px;
        z-index: 11;

        .column {
            width: 50%;
            height: 100%;
            border-left: 1px solid #fff000;
            border-right: 1px solid #fff000;
        }
    }

    .logo {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 10;
        transform: scale(0.5);
    }

    .controls {
        position: absolute;
        top: 30px;
        left: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 10;
        gap: 8px;
        width: 10%;

        .control {
            cursor: pointer;

            svg {
                width: 100%;
                display: block;
                height: 100%;
            }

            &.inactive {
                opacity: 0.35;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .content {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 9;

        .pfp, .threed {
            width: 100%;
            height: 100%;

            &.hidden {
                display: none;
            }
        }

        .pfp {
            img {
                height: 100%;
                width: 100%;
            }
        }

        .threed {
            model-viewer {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 600px) {
    .controls {
        width: 8%;
        top: 15px !important;
        left: 15px !important;
    }
}
