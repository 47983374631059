* {
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    background-color: #212121;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

#root {
    width: 100%;
    height: 100%;
}